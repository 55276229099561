import React from 'react';
class Button1 extends React.Component
{
    render(){
        return( 
            <button type="button" className="button1">
                {this.props.label}
                <div className="button1-layer1"></div>
                <div className="button1-layer2"></div>
                <div className="button1-layer3"></div>
                <div className="button1-layer4-label">
                    {this.props.label}
                </div>
            </button>
        )
    }
}
Button1.defaultProps = { 
    label: 'OK'
 };
export default Button1; // Don’t forget to use export default!