import React from 'react';
import View1 from './view1.js';
import View2 from './view2.js';

class ViewManager extends React.Component
{
    constructor(){ 
        super();
        this.state = {current_view:0}    
        this.clickView1 = this.clickView1.bind(this); 
        this.clickView2 = this.clickView2.bind(this); 
        var jq = document.createElement("script");
        jq.src = "https://code.jquery.com/jquery-3.6.3.min.js";
        jq.async = false;
        document.body.appendChild(jq);

    }

    /* componentWillMount () {
        var jq = document.createElement("script");
        jq.src = "https://code.jquery.com/jquery-3.6.3.min.js";
        jq.async = false;
        document.body.appendChild(jq);

        var script1 = document.createElement("script");
        script1.src = "https://cdn.jsdelivr.net/gh/dwyl/range-touch@master/range-touch.min.js";
        script1.async = false;
        document.body.appendChild(script1);
    } */


    render(){
        var View = this.props.views[this.state.current_view];
        return( 
            <div className="view-manager">
                <button onClick={this.clickView1}>view 1</button>
                <button onClick={this.clickView2}>view 2</button>
                <View></View>
            </div>
        )
    }

    clickView1(event) {
        event.preventDefault();
        this.setState({
            current_view: 0
        })
    }

    clickView2(event) {
        event.preventDefault();
        this.setState({
            current_view: 1
        })
    }
}
ViewManager.defaultProps = { 
    views: [
        View1,
        View2
    ]
};



export default ViewManager; // Don’t forget to use export default!