import React from 'react';
import Fader from './fader.js';
import Button1 from './button1.js';
import big_picture from './dancer-pic-big.png';

class View2 extends React.Component
{
    render(){
        return( 
            <div id="view2" className="page">
                <div className="left">
                {/* ROW 1 */}
                <div className="info-row left-row event-info">
                    <div className="flex-3-columns">
                        <div>
                            <div>
                            <div className="section-row row1">
                                <div className="section-label">Data xxx</div>
                                <div className="dancer-name">24/02</div>
                            </div>
                            </div>
                        </div>
                        <div>
                            <div className="section-row row1">
                                <div className="section-label">Evento</div>
                                <div className="dancer-name">Campeonato Nacional</div>
                            </div>
                        </div>
                        <div></div>
                    </div>
                </div>
                {/* ROW 2 */}
                <div className="info-row left-row juri-info">
                    <div className="flex-3-columns">
                    <div>
                        <div style={{"padding": "30px", "textAlign": "left"}}>
                        <div className="section-row row1">
                            <div className="section-label">Lugar</div>
                            <div className="dancer-name">#2</div>
                        </div>
                        </div>
                    </div>
                    <div style={{"minWidth": "600px"}}>
                        <div style={{"padding": "30px", "textAlign": "left"}}>
                        <div className="section-row row1">
                            <div className="section-label">Juri</div>
                            <div className="dancer-name">Julnako</div>
                        </div>
                        </div>
                    </div>
                    <div></div>
                    </div>
                </div>
                {/* ROW 3 - dancers */}
                <div className="left-row voting-panel">
                    {/* dancers being rated / slider */}
                    <div>
                        
                        <div className="rating-dancers">
                            {/* dancer1 */}
                            <div className="rating-dancer-info dancer1">
                                <div className="rating-dancer-stats">
                                    <div className="section-row row">
                                        <div className="section-label">BBoy</div>
                                        <div className="dancer-name">Y So Serious</div>
                                    </div>
                                    <div className="section-row row">
                                        <div className="section-column">
                                            <div className="section-label">Número</div>
                                            <div className="dancer-number">213</div>
                                        </div>
                                    </div>
                                    <div className="section-row row">
                                        <div className="section-column">
                                            <div className="section-label">Crew</div>
                                            <div className="dancer-number">Apolo 13</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="rating-dancer-big-photo">
                                    <img alt="dancer" src={big_picture}></img>
                                </div>
                            </div>
                            {/* vs */}
                            <div className="vs">VS</div>
                            {/* dancer2 */}
                            <div className="rating-dancer-info dancer2">
                                <div className="rating-dancer-stats">
                                    <div className="section-row row">
                                        <div className="section-label">BBoy</div>
                                        <div className="dancer-name">Y So Serious</div>
                                    </div>
                                    <div className="section-row row">
                                        <div className="section-column">
                                            <div className="section-label">Número</div>
                                            <div className="dancer-number">213</div>
                                        </div>
                                    </div>
                                    <div className="section-row row">
                                        <div className="section-column">
                                            <div className="section-label">Crew</div>
                                            <div className="dancer-number">Apolo 13</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="rating-dancer-big-photo">
                                    <img alt="dancer" src={big_picture}></img>
                                </div>
                            </div>
                        </div>
                        

                        <Fader></Fader>

                        <div className="rating-dancer-score">
                            <div className="section-row row1">
                                <div className="battle-score-table">
                                    <div className="table-column juri-names">
                                        <div className="header">Juri</div>
                                        <div className="body">
                                            <div className="row">
                                                Max
                                            </div>
                                            <div className="row">
                                                Julnako
                                            </div>
                                            <div className="row">
                                                Anthony
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-column">
                                        <div className="header">Round 1</div>
                                        <div className="body">
                                            <div className="row">
                                                <div className="result-color"></div>
                                            </div>
                                            <div className="row">
                                                <div className="result-color"></div>
                                            </div>
                                            <div className="row">
                                                <div className="result-color"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-column">
                                        <div className="header">Round 2</div>
                                        <div className="body">
                                            <div className="row">
                                                <div className="result-color"></div>
                                            </div>
                                            <div className="row">
                                                <div className="result-color"></div>
                                            </div>
                                            <div className="row">
                                                <div className="result-color"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-column">
                                        <div className="header">Round 3</div>
                                        <div className="body">
                                            <div className="row">
                                                <div className="result-color"></div>
                                            </div>
                                            <div className="row">
                                                <div className="result-color"></div>
                                            </div>
                                            <div className="row">
                                                <div className="result-color"></div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="section-column">
                                    <Button1 className="xxx" label="Avaliar"></Button1>
                                </div>
                            </div>
                        </div>
                        </div>            
                    </div>
                </div>
                <div className="right">
                    <div className="info-row">
                        <div>
                        <div className="section-label">Fase</div>
                        <div className="dancer-name">Pré-seleções</div>
                        </div>
                        <div>
                        <div className="section-label">Número de bboys</div>
                        <div className="dancer-name">43 Bboys</div>
                    </div>
                    </div>
                    {/* <div className="info-row">
                        <div>
                        <div className="section-label">Número de bboys</div>
                        <div className="dancer-name">43 Bboys</div>
                        </div>
                    </div> */}
                    <div className="general-score-table-wrapper">
                        <div>
                        {/* <table>
                            <thead>
                            <th>#</th>
                            <th>#</th>
                            <th>#</th>
                            </thead>
                            <tbody>
                            <tr>
                                <td>1</td>
                                <td>xxxxxxx</td>
                                <td>78.5</td>

                            </tr>
                            </tbody>
                        </table> */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default View2; // Don’t forget to use export default!