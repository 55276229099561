import React from 'react';
class DancerRing extends React.Component
{
    render(){
        return( 
            <div className={!!this.props.active?"active dancer":"dancer"}>
                <div className="dancer-photo-ring">
                    <div className="dancer-photo-ring-layer1"></div>
                    <div className="dancer-photo-ring-layer2"></div>
                    <div className="dancer-photo-ring-layer3"></div>
                    <div className="dancer-photo-ring-picture"></div>
                </div>
                <div className="dancer-name">
                    #{this.props.number} {this.props.name}
                </div>
            </div>
        )
    }
}
DancerRing.defaultProps = { 
    active: false,
    number: 'N/A',
    name: 'N/A'
 };
export default DancerRing; // Don’t forget to use export default!