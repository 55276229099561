import React from 'react';
class Fader extends React.Component
{
    constructor(){ 
        super();
        this.state = {value:50, faderValue: 0}    
        this.handleChange = this.handleChange.bind(this); 
    }
    render(){
        var faderValue = this.state.faderValue;
        //var s = "linear-gradient(90deg, rgba(255,0,0,0) 0%, rgba(0,0,0,1) 50%, rgba(0,0,255,0) 100%)";
        var s = "linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 50%, rgba(0,0,0,1) 100%)";
        if(faderValue > 0){

            s = "linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 50%, rgba(0,0,255,"+faderValue/100+") " + (50+faderValue/2) + "%, rgba(0,0,0,1) 0)"
        }
        else if(faderValue < 0){
            faderValue = faderValue*-1;
            s = "linear-gradient(90deg, rgba(0,0,0,1) "+(100-faderValue)/2+"%,rgba(255, 0, 0,"+faderValue/100+") "+(100-faderValue)/2+"%, rgb(0, 0, 0) 50%, rgb(0, 0, 0) 0px)"
        }
        var _style = {
            'background': s
        }
        return( 
            <div className="gauge-wrapper">
                <div className="gauge-button button-minus"></div>
                <div className="slider-wrapper">
                    <div className="range-custom">
                        <input type="range" onChange={this.handleChange} min="0" max="100" step="0.1" value={this.state.value}></input>
                        <div className="slider-track-mask4" style={_style}></div>
                        <div className="slider-track-mask3"></div>
                        <div className="slider-track-mask2"></div>
                        <div className="slider-track-mask1"></div>
                    </div>
                    <div className="slider-indicators slider-indicators-top">
                        <div className="slider-indicator-dash"></div>
                        <div className="slider-indicator-dash"></div>
                        <div className="slider-indicator-dash"></div>
                        <div className="slider-indicator-dash"></div>
                        <div className="slider-indicator-dash"></div>
                        <div className="slider-indicator-dash"></div>
                        <div className="slider-indicator-dash"></div>
                        <div className="slider-indicator-dash"></div>
                        <div className="slider-indicator-dash"></div>
                    </div>
                    <div className="slider-indicators slider-indicators-bottom">
                    <div className="slider-indicator-dash"></div>
                        <div className="slider-indicator-dash"></div>
                        <div className="slider-indicator-dash"></div>
                        <div className="slider-indicator-dash"></div>
                        <div className="slider-indicator-dash"></div>
                        <div className="slider-indicator-dash"></div>
                        <div className="slider-indicator-dash"></div>
                        <div className="slider-indicator-dash"></div>
                        <div className="slider-indicator-dash"></div>
                    </div>
                    <div className="slider-current-value" style={{left:(440*this.state.value/100)}}>
                        {Math.abs(this.state.faderValue)}
                    </div>
                </div>
                <div className="gauge-button button-plus"></div>
            </div>
        )
    }
    handleChange(event) {
        console.log(event.currentTarget.value)
        // check if the value is less than or greater than the starting value
        var value = event.currentTarget.value;
        var newValue = 0;
        if (value < 50) {
            newValue = -((50 - value) / 50) * 100;
        } else if (value > 50) {
            // convert the value to a positive value between 0 and 100
            newValue = ((value - 50) / 50) * 100;
        }
        newValue = Math.round(newValue * 10) / 10
        this.setState({
            value: event.currentTarget.value,
            faderValue: newValue
        })
    }

}
Fader.initialState = { 
    value: 0,
    faderValue: 0
 };

export default Fader; // Don’t forget to use export default!