import React from 'react';
import Slider from './slider.js';
import DancerRing from './dancer-ring.js';
import Button1 from './button1.js';
import big_picture from './dancer-pic-big.png';

class View1 extends React.Component
{
    constructor(){ 
        super();
        this.state = {
            event:{
                name: 'Campeonato Nacional - Lisboa',
                date: '24/02'
            },
            juri:{
                name: 'Julnako',
                seat: '2'
            },
            battle:{
                dancers:[
                    {name:'Deeogo', number:'99', active:false, score:30},
                    {name:'Found Kid', number:'40', active:false, score:20},
                    {name:'Aiam', number:'1', active:false, score:55},
                    {name:'A bboy with a long name', number:'54', active:false, score:60},
                ]
            },
            sliderKey: Math.random()
        }  
        this.setActiveDancer = this.setActiveDancer.bind(this); 
        this.renderActiveDancerInfo = this.renderActiveDancerInfo.bind(this); 
        this.sliderChanged = this.sliderChanged.bind(this);
    }

    render(){
        var dancers = this.state.battle.dancers;
        var active = dancers.find(obj => obj.active === true);
        return( 
            <div className="page">
                <div className="left">
                {/* ROW 1 */}
                <div className="info-row left-row event-info">
                    <div className="flex-3-columns">
                        <div className="section-row row1">
                            <div className="section-label">Data</div>
                            <div className="dancer-name">{this.state.event.date}</div>
                        </div>
                        <div className="section-row row1">
                            <div className="section-label">Evento</div>
                            <div className="dancer-name">{this.state.event.name}</div>
                        </div>
                        <div></div>
                    </div>
                </div>
                {/* ROW 2 */}
                <div className="info-row left-row juri-info">
                    <div className="flex-3-columns">
                    <div className="section-row row1">
                        <div className="section-label">Lugar</div>
                        <div className="dancer-name">#{this.state.juri.seat}</div>
                    </div>
                    <div className="section-row row1">
                        <div className="section-label">Juri</div>
                        <div className="dancer-name">{this.state.juri.name}</div>
                    </div>
                    <div></div>
                    </div>
                </div>
                {/* ROW 3 - dancers */}
                <div className="left-row voting-panel">
                    {/* current dancers in battle */}
                    <div className="current-dancers">
                        {
                            this.state.battle.dancers.map(function(dancer, index){
                                return(
                                    <button key={'dancer-ring-'+dancer.number}
                                        className="button-dancer-ring" type="button" onClick={this.setActiveDancer}
                                        data-index={index} data-value={dancer.number}>
                                        <DancerRing 
                                            active={!!active && (dancer.number===active.number)} number={dancer.number} name={dancer.name}
                                        ></DancerRing>
                                    </button>
                                )
                            }.bind(this))
                        }
                    </div>
                    {/* dancer being rated / slider */}
                    <div>
                        {this.renderActiveDancerInfo(active)}

                        <Slider key={this.state.sliderKey} value={!!active?Object.assign({},active).score:0} onChange={this.sliderChanged}></Slider>

                        <div className="flex-3-columns">
                            <div></div>
                            <div className="rating-dancer-score">
                                <div className="section-row row1">
                                    <div className="section-column">
                                        <div className="section-label">Pontuação</div>
                                        {!!active?
                                        <div className="dancer-name">{active.score}/100</div>
                                        :
                                        <div className="dancer-name">N/A</div>
                                        }
                                    </div>
                                    <div className="section-column">
                                        <Button1 className="xxx" label="Avaliar"></Button1>
                                    </div>
                                </div>
                                <div className="section-row row2">
                                    <button className="edit-dancer-score-button">Edit</button>
                                </div>
                            </div>
                            <div></div>
                        </div>
                    </div>            
                </div>
                </div>
                <div className="right">
                <div className="info-row">
                    <div>
                    <div className="section-label">Fase</div>
                    <div className="dancer-name">Pré-seleções</div>
                    </div>
                    <div>
                    <div className="section-label">Número de bboys</div>
                    <div className="dancer-name">43 Bboys</div>
                    </div>
                </div>
                <div className="general-score-table-wrapper">
                    <div>
                    </div>
                </div>
                </div>
            </div>
        )
    }

    sliderChanged(data){
        var battle = this.state.battle;
        var active = battle.dancers.find(obj => obj.active === true);
        if(!!active){
            active.score = data;
            this.setState({
                battle: battle
            })
        }
    }

    setActiveDancer(event) {
        var value = event.currentTarget.getAttribute('data-value');
        //var index = event.currentTarget.getAttribute('data-index');
        var battle = this.state.battle;
        battle.dancers = battle.dancers.map(function(dancer){
            if(dancer.number !== value){
                dancer.active = false;
            }
            else{
                dancer.active = true;
            }
            return dancer
        })
        this.setState({
            battle: battle,
            sliderKey: Math.random()
        })
    }

    renderActiveDancerInfo(active) {
        return(
            <div className="flex-3-columns">
                <div></div>
                {!active?
                <div className="rating-dancer-info">
                    <div className="rating-dancer-stats">
                        <h2>Seleciona um atleta para começar!</h2>
                    </div>
                </div>
                :
                <div className="rating-dancer-info">
                    <div className="rating-dancer-stats">
                        <div className="section-row row1">
                            <div className="section-label">BBoy</div>
                            <div className="dancer-name">{active.name}</div>
                        </div>
                        <div className="section-row row2">
                            <div className="section-column">
                            <div className="section-label">Número</div>
                            <div className="dancer-number">#{active.number}</div>
                            </div>
                            <div className="section-column">
                            <div className="section-label">Cidade</div>
                            <div className="dancer-number">{!!active.origin?active.origin:'N/A'}</div>
                            </div>
                            <div className="section-column">
                            <div className="section-label">Crew</div>
                            <div className="dancer-number">{!!active.crew?active.crew:'N/A'}</div>
                            </div>
                        </div>
                    </div>
                    <div className="rating-dancer-big-photo">
                        <img alt="dancer" src={big_picture}></img>
                    </div>
                </div>
                }
                <div></div>
            </div>
        )
    }
}
/* Slider.initialState = { 
    juri: {
        name: 'Julnako',
        seat: '2'
    }
}; */

export default View1; // Don’t forget to use export default!