import React from 'react';
class Slider extends React.Component
{
    constructor(props) {
        super(props);
        console.log(props)
        this.state = { value: props.value };
        this.handleChange = this.handleChange.bind(this);
        
    }
    render(){
        return( 
            <div className="gauge-wrapper">
                <div className="gauge-button button-minus"></div>
                <div className="slider-wrapper">
                    <div className="range-custom">
                        <input id="xxx" type="range" onChange={this.handleChange} min="0" max="100" step="0.1" value={this.state.value}></input>
                        <div className="slider-track-mask4"></div>
                        <div className="slider-track-mask3"></div>
                        <div className="slider-track-mask2"></div>
                        <div className="slider-track-mask1"></div>
                    </div>
                    <div className="slider-indicators slider-indicators-top">
                        <div className="slider-indicator-dash"></div>
                        <div className="slider-indicator-dash"></div>
                        <div className="slider-indicator-dash"></div>
                        <div className="slider-indicator-dash"></div>
                        <div className="slider-indicator-dash"></div>
                        <div className="slider-indicator-dash"></div>
                        <div className="slider-indicator-dash"></div>
                        <div className="slider-indicator-dash"></div>
                        <div className="slider-indicator-dash"></div>
                    </div>
                    <div className="slider-indicators slider-indicators-bottom">
                    <div className="slider-indicator-dash"></div>
                        <div className="slider-indicator-dash"></div>
                        <div className="slider-indicator-dash"></div>
                        <div className="slider-indicator-dash"></div>
                        <div className="slider-indicator-dash"></div>
                        <div className="slider-indicator-dash"></div>
                        <div className="slider-indicator-dash"></div>
                        <div className="slider-indicator-dash"></div>
                        <div className="slider-indicator-dash"></div>
                    </div>
                    <div className="slider-current-value" style={{left:(440*this.state.value/100)}}>
                        {this.state.value}
                    </div>
                </div>
                <div className="gauge-button button-plus"></div>
            </div>
        )
    }

    handleChange(event) {
        var _value = event.currentTarget.value
        this.setState({
            value: _value
        },()=>{
            console.log('V:',_value)
            if(!!this.props.onChange){
                this.props.onChange(_value)
            }
        })
    }

}
Slider.defaultProps = { 
    value: 0
 };

export default Slider; // Don’t forget to use export default!